<template>
  <!-- top -->
  <div>
    <div class="top" v-bind:class="{ topOpacity: isOpacity }">
      <div class="wrap flex">
        <div class="logo">
          <img src="@/assets/images/logo.png" alt="" />
          <div style="font-size: 40px;margin-left: 10px;color: #59ca52;">胡杨快车</div>
        </div>
        <div class="nav">
          <ul class="flex">
            <li>
              <a
                href="javascript:;"
                v-on:click="scrollToElement('banner')"
                class="current"
                >首页</a
              >
            </li>
            <li>
              <a href="javascript:;" v-on:click="scrollToElement('row_1')"
                >出行服务</a
              >
            </li>
            <li>
              <a href="javascript:;" v-on:click="scrollToElement('row_2')"
                >核心业务</a
              >
            </li>
            <li>
              <a href="javascript:;" v-on:click="scrollToElement('row_3')"
                >新闻资讯</a
              >
            </li>
            <li>
              <a href="javascript:;" v-on:click="scrollToElement('row_4')"
                >关于我们</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="top_bg"></div>

    <!-- banner -->
    <div class="banner" id="banner">
      <img src="@/assets/images/banner.png" alt="" />
    </div>

    <div class="row" id="row_1">
      <div class="wrap">
        <div class="block_title">
          <img src="@/assets/images/title_1.png" alt="" />
        </div>
        <div class="block_list_1">
          <div class="item">
            <div class="pic">
              <img src="@/assets/images/icon_1.png" alt="" />
            </div>
            <div class="text">智能定位 一键下单</div>
            <p>
              基于“LBS地理位置服务 + 智能手机客户端 + 智能手机司机端 +
              线上下单线下服务”的出行服务新模式。
            </p>
          </div>
          <div class="item">
            <div class="pic">
              <img src="@/assets/images/icon_2.png" alt="" />
            </div>
            <div class="text">分秒必争 快速响应</div>
            <p>
              通过北斗全球定位和大数据智能分析系统根据车头朝向快速派单给司机，以此减少客户的等待时间。
            </p>
          </div>
          <div class="item">
            <div class="pic">
              <img src="@/assets/images/icon_3.png" alt="" />
            </div>
            <div class="text">优化资源 就近上车</div>
            <p>
              “胡杨快车”突破了传统出行模式的束缚科学的优化资源配置，能够有效的组织闲散的司机资源。
            </p>
          </div>
          <div class="item">
            <div class="pic">
              <img src="@/assets/images/icon_4.png" alt="" />
            </div>
            <div class="text">价格实惠 收费透明</div>
            <p>
              根据客户出发地与目的地之前的行程距离计算出行费用，单价更低，费用清晰透明。
            </p>
          </div>
        </div>

        <div class="block_title mt60">
          <img src="@/assets/images/title_2.png" alt="" />
        </div>

        <div class="block_list_2">
          <div class="item">
            <div class="pic">
              <img src="@/assets/images/duoyang_1.png" alt="" />
            </div>
            <div class="text">扫码叫车</div>
          </div>
          <div class="item">
            <div class="pic">
              <img src="@/assets/images/duoyang_2.png" alt="" />
            </div>
            <div class="text">公众号叫车</div>
          </div>
          <div class="item">
            <div class="pic">
              <img src="@/assets/images/duoyang_3.png" alt="" />
            </div>
            <div class="text">小程序叫车</div>
          </div>
          <div class="item">
            <div class="pic">
              <img src="@/assets/images/duoyang_4.png" alt="" />
            </div>
            <div class="text">APP下单叫车</div>
          </div>
          <div class="item">
            <div class="pic">
              <img src="@/assets/images/duoyang_5.png" alt="" />
            </div>
            <div class="text">电话叫车</div>
          </div>
        </div>
      </div>
    </div>

    <div class="row bg_black">
      <div class="wrap">
        <div class="block_title">
          <img src="@/assets/images/title_3.png" alt="" />
        </div>

        <div class="block_list_3">
          <div class="item">
            <div class="pic">
              <img src="@/assets/images/anquan_1.png" alt="" />
            </div>
            <div class="text">实名认证</div>
          </div>
          <div class="item">
            <div class="pic">
              <img src="@/assets/images/anquan_2.png" alt="" />
            </div>
            <div class="text">行程分享</div>
          </div>
          <div class="item">
            <div class="pic">
              <img src="@/assets/images/anquan_3.png" alt="" />
            </div>
            <div class="text">行程分享</div>
          </div>
          <div class="item">
            <div class="pic">
              <img src="@/assets/images/anquan_4.png" alt="" />
            </div>
            <div class="text">录音录像</div>
          </div>
          <div class="item">
            <div class="pic">
              <img src="@/assets/images/anquan_5.png" alt="" />
            </div>
            <div class="text">录音录像</div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" id="row_2">
      <div class="wrap1500">
        <div class="block_title">
          <img src="@/assets/images/title_4.png" alt="" />
        </div>

        <div class="block_content">
          <h5>胡杨快车乘客端</h5>
          <p>
            胡杨快车是一款网约车服务平台，乘客可通过该平台实现叫车服务，我们致力于通过线上技术手段不断优化乘客乘车体验及乘客安全保护，在车辆行驶中，我们上线了一键报警、行程分享、紧急联系人、录音录像等功能，同时行后也上线服务监督体系，乘客可通过在线匿名评分对司机服务进行评价。
          </p>
          <ul>
            <li>
              <div class="pic">
                <img src="@/assets/images/page_1.png" alt="" />
              </div>
              <h6>下单页面</h6>
            </li>
            <li>
              <div class="pic">
                <img src="@/assets/images/page_2.png" alt="" />
              </div>
              <h6>行驶中页面</h6>
            </li>
            <li>
              <div class="pic">
                <img src="@/assets/images/page_3.png" alt="" />
              </div><h6>费用支付页面</h6>
            </li>
            <li>
              <div class="pic">
                <img src="@/assets/images/page_4.png" alt="" />
              </div>
              <h6>个人中心页面</h6>
            </li>
            <li>
              <div class="pic">
                <img src="@/assets/images/page_5.png" alt="" />
              </div>
              <h6>订单查询页面</h6>
            </li>
          </ul>
          <div class="bg"></div>
        </div>

        <div class="block_content">
          <h5>胡杨快车司机端</h5>
          <p>
            胡杨快车通过严格审核把控司机质量，旨在培养一批高质量、服务好的司机队伍，我们精益求精，通过完善价格结构，不断提升司机收入，同时考虑到司机驾驶安全，我们上线疲劳驾驶报警，确保司机在休息好的同时更安全的服务乘客，减少疲劳驾驶带来的安全风险。
          </p>
          <ul>
            <li>
              <div class="pic">
                <img src="@/assets/images/page_6.png" alt="" />
              </div>
              <h6>司机认证页面</h6>
            </li>
            <li>
              <div class="pic">
                <img src="@/assets/images/page_7.png" alt="" />
              </div>
              <h6>行驶中页面</h6>
            </li>
            <li>
              <div class="pic">
                <img src="@/assets/images/page_8.png" alt="" />
              </div>
              <h6>路线导航页面</h6>
            </li>
            <li>
              <div class="pic">
                <img src="@/assets/images/page_9.png" alt="" />
              </div>
              <h6>取消订单页面</h6>
            </li>
            <li>
              <div class="pic">
                <img src="@/assets/images/page_10.png" alt="" />
              </div>
              <h6>订单查询页面</h6>
            </li>
          </ul>
          <div class="bg"></div>
        </div>
      </div>
    </div>

    <div class="row bk_bg" id="row_3">
      <div class="wrap">
        <div class="block_title">
          <img src="@/assets/images/title_5.png" alt="" />
        </div>
        <div class="bk_wrap">
          <div class="list">
            <div class="ul">
              <div>
                <h6>1</h6>
                <p>持有C1驾照，3年以上驾龄，男女不限</p>
              </div>
              <div>
                <h6>2</h6>
                <p>无重大交通事故，无犯罪记录，无纹身</p>
              </div>
              <div>
                <h6>3</h6>
                <p>年龄在22-55周岁，有做过网约车司机优先考虑，无户籍限制</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row bg_black" id="row_4">
      <div class="wrap">
        <div class="block_title">
          <img src="@/assets/images/title_6.png" alt="" />
        </div>
        <div class="about mt60 flex">
          <div class="pic">
            <img src="@/assets/images/about.png" alt="" />
          </div>
          <div class="text">
            <p>
              胡杨快车是一家一站式智能网约车出行服务平台，公司以“最值得用户信赖的网约车服务”为愿景，以“满足用户出行需求”为使命，致力于为用户提供安全、便捷的出行服务。
            </p>
            <p>
              胡杨快车希望通过为大众提供便捷的出行服务不断推动中国公路道路运输行业的发展，加强城市与城市之间的沟通联系，从而促进社会各行各业经济发展。
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="row bg_footer">
      <div class="wrap text_center">
        <a class="copyright" href="javascript:;" v-on:click="openNewPage()" >新ICP备2024010203号-1</a>
      </div>
    </div> -->
  </div>
</template>
<script>
export default {
  name: "Index",
  data() {
    return {
      timer: null, // 滚动动画计时器
      stopScroll: true, // 取消滚动
      isOpacity: false, // 导航条是否透明
    };
  },
  methods: {
    // 获取元素距文档顶端距离
    getElementHeight(elemID) {
      let element = document.querySelector("#" + elemID);
      return element.offsetTop;
    },

    // 滚动到对应位置
    scrollToElement(elemID) {
      let that = this;
      let elementHeigth = this.getElementHeight(elemID);

      clearInterval(that.timer)
      that.timer = setInterval(function () {
        that.stopScroll = true;
        //获取滚动条的滚动高度
        let osTop =
          document.scrollingElement.scrollTop == 0
            ? 10
            : document.scrollingElement.scrollTop;
        //用于设置速度差，产生缓动的效果
        let speed = 200;
        let scrollTop = osTop + speed;
        
        console.log('scrollTop',scrollTop);
        console.log('elementHeigth',elementHeigth);

        if (scrollTop >= elementHeigth) {
          clearInterval(that.timer);
          scrollTop = elementHeigth - 100; // 100是漂浮导航的高度
        }
        document.scrollingElement.scrollTop = scrollTop;
      }, 30);
    },
    openNewPage() {
      window.open('https://beian.miit.gov.cn');
    },
    // 监听滚动事件
    handleScroll() {
      let that = this;
      let scTop = document.scrollingElement.scrollTop;
      this.isOpacity = scTop > 0 ? true : false;

      // 滚动时取消滚动动画事件
      if (that.stopScroll == false) clearInterval(that.timer);
      that.stopScroll = false;
    },
  },
  created() {},
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
  },
};
</script>
<style scoped src='@/assets/css/index.css'>
</style>